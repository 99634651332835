<template>
  <v-row>
      <v-col md="auto">
        <v-card elevation="6" height="100%">
          <v-navigation-drawer permanent floating :expand-on-hover="expandOnHover">
            <v-list dense nav>
              <v-list-item
                v-for="item in filteredItems"
                :key="item.title"
                link
                :to="item.link"
                color="primary"
              >
                <v-list-item-icon>
                  <v-icon color="primary">{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title color="primary">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-navigation-drawer>
        </v-card>
      </v-col>
      <v-col>
        <v-container>
          <router-view />
        </v-container>
      </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Menu',
  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    items: [
      { title: 'Solicitações', icon: 'mdi-home-outline', link: '/requests' },
      { title: 'Controle de usuários', icon: 'mdi-account-group-outline', link: '/users', role: [2, 3, 4] },
      { title: 'Imobiliárias', icon: 'mdi-home-city-outline', link: '/real-estate/list', role: [2, 3] },
      { title: 'Modelos de parecer', icon: 'mdi-file-document-outline', link: '/feedback-model/list', role: [2, 3] },
      { title: 'Mensagens', icon: 'mdi-chat-outline', link: '/chats', role: [2, 3] },
      { title: 'Simulador de aluguel', icon: 'mdi-currency-usd', link: '/rental-simulator', role: [4, 5] }
    ]
  }),
  computed: {
    ...mapGetters([
      'userRole'
    ]),
    filteredItems () {
      return this.items.filter(item => {
        return !item.role || item.role.includes(this.userRole)
      })
    }
  }
}
</script>

<style>

</style>
