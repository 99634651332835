<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        v-bind="attrs"
        v-on="on"
      >
        Sair
      </v-btn>
    </template>
    <v-card>
      <v-card-text class="pl-2 pr-2 pt-1 pb-1">
        <v-container>
          <v-row>
            <v-col class="pa-0 ma-0">
              <h5 class="bold">Tem certeza de que deseja sair? Todo o trabalho não salvo poderá ser perdido!</h5>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="dialog = false"
          rounded
        >
          Cancelar
        </v-btn>
        <v-btn
          color="error"
          @click=logout
          :loading=loading
          rounded
          width="120px"
        >
          Sair
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import auth from "@/services/auth";

export default {
  name: "LogoutConfirmDialog",
  data: () => ({
    dialog: false,
    loading: false
  }),
  methods: {
    logout() {
      this.loading = true
      try {
        auth.logout()
        this.$router.go()
      } catch {
        this.loading = false
      }
    },
  },
};
</script>
