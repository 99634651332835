<template>
  <v-app>
    <Navbar @changeMenuTemplate="toggleExpandOnHover"></Navbar>
    <Menu :expandOnHover=expandOnHover></Menu>
    <RealStateChat v-if="showChat && isRealEstateUser"></RealStateChat>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'
import Navbar from './Navbar'
import Menu from './Menu'
import RealStateChat from '@/components/Chat/RealStateChat.vue'

export default {
  components: {
    Navbar,
    Menu,
    RealStateChat
  },
  data: () => ({
    expandOnHover: true,
    showChat: false
  }),
  computed: {
    activeUser () {
      return this.$store.getters.activeUser
    },
    isRealEstateUser () {
      return this.$store.getters.isRealEstateUser
    }
  },
  beforeMount () {
    this.getActiveUser().then(() => {
      this.$store.dispatch('chat/chatAuth').then(() => {
        this.$store.dispatch('chat/listenChat').finally(() => {
          this.showChat = true
        })
      })
      this.getIncomeSourceList()
      this.getStatesList()
      this.getMaritalStatusList()
      this.getSituationsList()
      this.getServiceTypeList()
      this.getFinancialProfileList()
      this.getDocumentTypeList()
      this.getPretensionSolicitationList()
      this.getAttendantsList()
      this.getComplementaryDocumentTypeList()
    })
  },
  created () {
    // this.$router.push('/solicitacoes')
  },
  methods: {
    ...mapActions([
      'getIncomeSourceList',
      'getStatesList',
      'getMaritalStatusList',
      'getSituationsList',
      'getServiceTypeList',
      'getFinancialProfileList',
      'getDocumentTypeList',
      'getPretensionSolicitationList',
      'getAttendantsList',
      'getComplementaryDocumentTypeList',
      'getActiveUser'
    ]),
    toggleExpandOnHover () {
      this.expandOnHover = !this.expandOnHover
      return true
    }
  }
}
</script>
