<template>
  <v-app-bar app color="white" dark>
    <v-app-bar-nav-icon color="black" @click="$emit('changeMenuTemplate')" x-large></v-app-bar-nav-icon>
    <div class="d-flex align-center">
      <v-img
        alt="Vuetify Logo"
        class="shrink mr-2 ml-1"
        contain
        src="@/assets/images/rb_logo.png"
        transition="scale-transition"
        width="200"
      />

      <!-- <v-img
        alt="Vuetify Name"
        class="shrink mt-1 hidden-sm-and-down"
        contain
        min-width="100"
        src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
        width="100"
      /> -->
    </div>

    <v-spacer></v-spacer>
    <div id="nav">
      <v-chip color="dark" outlined v-if="isRealEstateUser" class="mr-4">
        <strong>{{ userRealEstate }}</strong>
      </v-chip>
      <v-menu bottom right offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ma-0 pa-0" dark icon x-large v-bind="attrs" v-on="on" @click="setHasNewNotification(false)">
            <v-badge
              color="error"
              overlap
              dot
              offset-x="12"
              offset-y="14"
              content="1"
              v-if="hasNewNotification"
            >
            <!-- TODO: Acertar para aparecer a quantidade de mensagens  -->
              <v-icon color="dark">mdi-bell-outline</v-icon>
            </v-badge>
            <v-icon v-else color="dark">mdi-bell-outline</v-icon>
          </v-btn>
        </template>

        <v-list v-if="notificationList.length">
          <v-list-item v-for="(notification, i) in notificationList" :key="i">
            <span>{{notification.from.nome + ' ' + notification.text}}</span>
          </v-list-item>
        </v-list>
        <v-list v-else>
          <v-list-item>
            <span>Sem novas notificações</span>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu bottom right offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ma-0 pa-0" dark icon x-large v-bind="attrs" v-on="on">
            <v-icon color="dark">mdi-account-circle</v-icon>
          </v-btn>
        </template>

        <v-list>

          <!-- <v-list-item v-if=isRootUser>
            <v-list-item-title>
              <AdminVision></AdminVision>
            </v-list-item-title>
          </v-list-item> -->

          <v-list-item>
            <v-list-item-title>
              <UserFormDialog :itemId=itemId :isPerfil=true></UserFormDialog>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <LogoutConfirmDialog></LogoutConfirmDialog>
            </v-list-item-title>
          </v-list-item>

        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import LogoutConfirmDialog from '@/components/Logout/ConfirmDialog'
// import AdminVision from '@/components/Admin/Vision'
import UserFormDialog from '@/components/User/FormDialog'

export default {
  name: 'Navbar',
  components: {
    LogoutConfirmDialog,
    // AdminVision,
    UserFormDialog
  },
  computed: {
    ...mapGetters([
      'userRealEstate',
      'isRealEstateUser',
      'isRootUser',
      'activeUser'
    ]),
    itemId () {
      return this.activeUser.id
    },
    notificationList () {
      return this.$store.getters['chat/getNotificationList']
    },
    hasNewNotification () {
      return this.$store.getters['chat/getHasNewNotification']
    }
  },
  watch: {
    notificationList (val) {
      if (val) {
        this.notificationList.forEach(i => {
          this.$toastr.Add({
            name: "sendNotificationMessages",
            title: i.from.nome,
            msg: i.text,
            clickClose: true,
            type: "info",
            timeout: 50000,
            progressbar: true,
            progressBarValue: null,
            classNames: ["animated", "zoomInUp", "injectedStyle"],
            style: { backgroundColor: "#00877F", width: "400px" }
          });
        })
      }
    },
  },
  methods: {
    setHasNewNotification (val) {
      return this.$store.commit('chat/setHasNewNotification', val)
    }
  }
}
</script>

<style>
.injectedStyle {
  .toast-title {
    color: #E9E9E9;
    font-family: 'Open Sans', Arial, sans-serif;
  }
  .toast-message {
    color: #E9E9E9;
    font-family: 'Open Sans', Arial, sans-serif;  
  }
}
</style>
