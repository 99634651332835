<template>
  <div>
    <BeautifulChat
      :participants="participants"
      :titleImageUrl="titleImageUrl"
      :onMessageWasSent="onMessageWasSent"
      :messageList="messageList"
      :newMessagesCount="newMessagesCount"
      :isOpen="isChatOpen"
      :close="closeChat"
      :open="openChat"
      :showEmoji="false"
      :showFile="false"
      :showEdition="true"
      :showDeletion="true"
      :showHeader="true"
      :showTypingIndicator="showTypingIndicator"
      :showLauncher="true"
      :showCloseButton="true"
      :colors="colors"
      :alwaysScrollToBottom="alwaysScrollToBottom"
      :disableUserListToggle="true"
      :messageStyling="messageStyling"
      @onType="handleOnType"
      @edit="editMessage"
      placeholder="Escreva aqui sua mensagem..."
    >
      <template v-slot:header>
        <v-row class="mt-4 ml-5">{{ getUsuarioDestinoNome ? getUsuarioDestinoNome : 'Real Benefícios' }}</v-row>
      </template>
    </BeautifulChat>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BeautifulChat from '../../components/Chat/BeautifulChat/Launcher.vue'

export default {
  components: {
    BeautifulChat
  },
  data () {
    return {
      participants: [], // the list of all the participant of the conversation. `name` is the user name, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
      titleImageUrl: 'null',
      newMessagesCount: 0,
      isChatOpen: false, // to determine whether the chat window should be open or closed
      showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
      colors: {
        header: {
          bg: '#00A79D',
          text: '#ffffff'
        },
        launcher: {
          bg: '#00A79D'
        },
        messageList: {
          bg: '#ffffff'
        },
        sentMessage: {
          bg: '#00A79D',
          text: '#ffffff'
        },
        receivedMessage: {
          bg: '#eaeaea',
          text: '#222222'
        },
        userInput: {
          bg: '#f4f7f9',
          text: '#565867'
        }
      }, // specifies the color scheme for the component
      alwaysScrollToBottom: true, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
      messageStyling: false // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
    }
  },
  computed: {
    ...mapGetters({
      getUsuarioDestinoNome: 'chat/getUsuarioDestinoNome'
    }),
    messageList () {
      return this.$store.getters['chat/getMessageList']
    },
    usuarioDestinoId () {
      return this.$store.getters['chat/getUsuarioDestinoId']
    }
  },
  mounted () {
    this.setParticipants()
    this.$store.dispatch('chat/getAllMessages', this.usuarioDestinoId)
  },
  methods: {
    // TODO: Pegar dados atendente após algum assumir a conversa
    setParticipants () {
      this.participants = [
        {
          id: null,
          name: 'support',
          imageUrl: null
        }
      ]
    },
    sendMessage (text) {
      if (text.length > 0) {
        this.newMessagesCount = this.isChatOpen ? this.newMessagesCount : this.newMessagesCount + 1
        this.onMessageWasSent({ author: 'me', type: 'text', data: { text } })
      }
    },
    // TODO: Acertar para envio de emoji
    onMessageWasSent (message) {
      // console.log(message)
      // called when the user sends a message
      const payload = {
        _message: message.data.text,
        _usuarioDestinoId: this.usuarioDestinoId
      }
      this.$store.dispatch('chat/sendMessage', payload).then(() => {
        this.$store.commit('chat/pushNewMessage', message)
      })
    },
    openChat () {
      // called when the user clicks on the fab button to open the chat
      this.isChatOpen = true
      this.newMessagesCount = 0
    },
    closeChat () {
      // called when the user clicks on the botton to close the chat
      this.isChatOpen = false
    },
    handleScrollToTop () {
      // called when the user scrolls message list to top
      // leverage pagination for loading another page of messages
    },
    handleOnType () {
      // console.log('Emit typing event')
    },
    editMessage (message) {
      const m = this.messageList.find(m => m.id === message.id)
      m.isEdited = true
      m.data.text = message.data.text
    }
  }
}
</script>
